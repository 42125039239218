<template>
  <div>
    <PSection pubg-result-22s3>
      <div class="inner-holder">
        <SectionTitle title-ko="대회 결과" black sm />
        <div class="contents-holder">
          <div class="empty" v-if="!isResult" />
          <div v-else class="result">
            <PItem tag="h2" class="logo">PUBG LVUP SHOWDOWN</PItem>
            <article>
              <div class="final-list" v-if="showFinalList">
                <FinalMemberTable finished benefitLegend="차기 프로대회 진출권을 확보한 팀" :mark-size="2" title="PLS 시즌 3 결선 결과" arena-id="62fc95ef8684d3000797fcd9" :group-match="groupMatch" />
              </div>
              <PItem tag="h3">PLS 시즌 3 예선 / 본선 결과</PItem>
              <PItem class="week-data">
                <dl v-for="(item, i) in weekData" :key="`week-${i}`">
                  <dt>
                    <span>{{ i + 1 }}주차</span>
                  </dt>
                  <dd class="date" v-html="item.preliminaryDate"></dd>
                  <dd class="link">
                    <button @click="showQualifierResult(i)" :class="{ active: checkQualifierActive(i) }">예선 결과</button>
                  </dd>
                  <dd class="date" v-html="item.finalDate"></dd>
                  <dd class="link">
                    <button @click="showFinalResult(i)" :class="{ active: checkFinalActive(i) }">본선 결과</button>
                  </dd>
                </dl>
              </PItem>
            </article>
          </div>
          <div class="prev-leaderboard" :class="{ open }">
            <button class="main-btn" @click="openResult">
              <span v-if="!open">시즌2 최종 결과 펼쳐보기</span>
              <span v-else>시즌2 최종 결과 접기</span>
            </button>
            <p v-if="!open">클릭하여 시즌2 결과를 확인해 보세요.</p>
            <template v-if="open">
              <article>
                <PItem class="rank-data">
                  <dl v-for="(item, i) in prevFinalData" :key="`rank-${i}`">
                    <dt data-label="순위">{{ item.label }}</dt>
                    <dd data-label="팀명" v-html="item.teamName"></dd>
                    <dd data-label="순위 보상" v-html="item.reword"></dd>
                  </dl>
                </PItem>
                <FinalMemberTable :mark-size="4" title="PLS 시즌 2 결선 결과" arena-id="62382d4e725fba0007f02a19" />
              </article>
            </template>
          </div>
        </div>
      </div>
    </PSection>
  </div>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import { historyMove } from 'shared/utils/routerUtils';
import SectionTitle from '@/views/components/common/SectionTitle.vue';
import FinalMemberTable from '@/views/components/pls_2022_3/leagueResult/FinalMemberTable.vue';

export default {
  name: 'PubgResult',
  components: { FinalMemberTable, SectionTitle },
  mixins: [gtag],
  data() {
    return {
      isResult: true,
      showFinalList: true,
      open: false,
      weekData: [
        { preliminaryDate: '9월 5일 <s>(월)</s>', finalDate: '9월 6일 <s>(화)</s>', preliminaryLink: 'https://lvup.gg/arena/pubg/62fc95d48684d3000797fcce/bracket?stage-id=6315c1a116926b00071c29a9&group=1', finalLink: 'https://lvup.gg/arena/pubg/62fc95d48684d3000797fcce/bracket?stage-id=6315ff1371deaa000712a84b&group=1' },
        { preliminaryDate: '9월 13일 <s>(화)</s>', finalDate: '9월 14일 <s>(수)</s>', preliminaryLink: 'https://lvup.gg/arena/pubg/62ff223ba83ce600077c31ee/bracket?stage-id=63204dc00f5c32000727dae0&group=1', finalLink: 'https://lvup.gg/arena/pubg/62ff223ba83ce600077c31ee/bracket?stage-id=632088030c8eea000769320f&group=1' },
        { preliminaryDate: '9월 19일 <s>(월)</s>', finalDate: '9월 20일 <s>(화)</s>', preliminaryLink: 'https://lvup.gg/arena/pubg/62ff224fa83ce600077c31f9/bracket?stage-id=632836a90f5c320007281d46&group=1', finalLink: 'https://lvup.gg/arena/pubg/62ff224fa83ce600077c31f9/bracket?stage-id=63286e81bc37dc00076c7b23&group=1' },
        { preliminaryDate: '9월 26일 <s>(월)</s>', finalDate: '9월 27일 <s>(화)</s>', preliminaryLink: 'https://lvup.gg/arena/pubg/62ff225ba83ce600077c3204/bracket?stage-id=633171310c8eea00070aa19f&group=1', finalLink: 'https://lvup.gg/arena/pubg/62ff225ba83ce600077c3204/bracket?stage-id=6331a912bc37dc00076cae57&group=1' },
        { preliminaryDate: '10월 3일 <s>(월)</s>', finalDate: '10월 4일 <s>(화)</s>', preliminaryLink: 'https://lvup.gg/arena/pubg/62ff226ba83ce600077c320f/bracket?stage-id=633aabb0bc37dc0007d32f0d&group=1', finalLink: 'https://lvup.gg/arena/pubg/62ff226ba83ce600077c320f/bracket?stage-id=633ae2e30f5c320007b722b8&group=1' },
      ],
      prevFinalData: [
        { label: '1위', teamName: '<span>BGP</span><b>PWS 진출</b>', reword: '3,000,000원<br/><s>벤큐 2546K 모니터</s>' },
        { label: '2위', teamName: '<span>No-Name</span><b>PWS 진출</b>', reword: '2,000,000원' },
        { label: '3위', teamName: '<span>Veronica7</span>', reword: '1,000,000원' },
        { label: '4위', teamName: '<span>Carpediem</span><b>PWS 진출</b>', reword: '-' },
        { label: '5위', teamName: '<span>HulkGaming</span><b>PWS 진출</b>', reword: '-' },
      ],
      groupMatch: [
        { groupName: 'B', name: 'GCA No-Name' },
        { groupName: 'C', name: 'RIP.' },
        { groupName: 'C', name: 'FOCUS ROOM' },
        { groupName: 'C', name: 'Mirae-n Ventures' },
        { groupName: 'A', name: 'KGA Hermes' },
        { groupName: 'A', name: 'Overwhelm' },
        { groupName: 'B', name: 'MARU Gaming' },
        { groupName: 'B', name: 'APE prince' },
        { groupName: 'A', name: 'Galaxy P Gaming' },
        { groupName: 'A', name: 'DCA assemble' },
        { groupName: 'B', name: 'Team Fearless' },
        { groupName: 'A', name: 'GCA AST' },
        { groupName: 'B', name: 'AFJAMMINZ' },
        { groupName: 'C', name: 'Team Daily' },
        { groupName: 'B', name: 'DK2' },
        { groupName: 'C', name: 'Eagle Owls Challengers' },
        { groupName: 'A', name: 'HUG SOFT' },
        { groupName: 'C', name: 'PDB' },
        { groupName: 'B', name: 'BSGGunSanGaming' },
        { groupName: 'A', name: 'Crows Zero' },
        { groupName: 'A', name: 'GCA Crescendo' },
        { groupName: 'C', name: 'TMI' },
        { groupName: 'B', name: 'ALL SLIP' },
        { groupName: 'C', name: 'Carpediem' },

      ],
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    openResult() {
      this.trackEvent(`${this.$route.meta.leagueCode}_result_season2`, 'click', `${this.upRouteGameId}`);
      this.open = !this.open;
    },
    checkQualifierActive(idx) {
      return this.weekData[idx].preliminaryLink;
    },
    checkFinalActive(idx) {
      return this.weekData[idx].finalLink;
    },
    showQualifierResult(idx) {
      this.trackEvent(`${this.$route.meta.leagueCode}_result_day${idx + 1}_prematch_01`, 'click', `${this.upRouteGameId}`);
      historyMove(`${this.weekData[idx].preliminaryLink}`);
    },
    showFinalResult(idx) {
      this.trackEvent(`${this.$route.meta.leagueCode}_result_day${idx + 1}_prematch_02`, 'click', `${this.upRouteGameId}`);
      historyMove(`${this.weekData[idx].finalLink}`);
    },
    goArena() {
      this.trackEvent('result_season1_Detail', 'click', `${this.upRouteGameId}`);
      historyMove('https://lvup.gg/arena/pubg/61b051dec780ee00076b012e/bracket?stage-id=61fb969f493ae200070d5fd4&group=1');
    },
    checkedQuery() {
      if (this.$route.query.page && this.$route.query.page === 'pls1') {
        setTimeout(() => {
          const media = this.matchedMediaDevice;
          const target = document.querySelector('.prev-leaderboard');
          this.open = true;
          this.$scroll.scrollMove(target.offsetTop + (media === 'D' ? 100 : 60), 1000);
        }, 200);
      }
    },
  },
  mounted() {
    this.checkedQuery();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[pubg-result-22s3] {
  .inner-holder { .p(40, 20, 60); .font-g;
    [section-title] {
      .ko {.fs(20, 20); .bold; .font-g; .ls(-1); .mb(20); }
    }
    .contents-holder {.wh(100%); .bgc(#1e2127); .p(100, 20, 100, 20); .font-g; .rel;
      &:before { .cnt; .abs; .bg('@{pubg-result}/bg-img-m.svg'); .wh(100%, 71); .lt(0);}
      &:after {.cnt; .abs; .bg('@{pubg-result}/bg-img-m.svg'); .wh(100%, 71); .lb(0);}
      .empty { .wh(100%, 204); .fs(14); .tc; .bg-c; .no-repeat('@{pubg-result}/empty_result_m.svg');.br(4); .items-center; .flex-middle; .c(rgba(255, 255, 255, .5));
      }

      h2 { .mb(72); .wh(212, 74); .fs(0, 0); .mh-c; .no-repeat('@{pubg-result}/bg_result_logo.svg'); .bg-s(100%, 100%); .ani-base;
        .intro-up(1, { .ani-on; });
      }

      .final-list { .mb(40); }

      .week-data { .rel; .ani-base; .c(white);
        .intro-up(1, { .ani-on; });
        dl { .flex;
          + dl { .mt(6); }
        }
        dt, dd { .flex-center; .h(48); }
        dt { flex: none; .w(40); .medium; .fs(13); .mr(2); background: linear-gradient(101.01deg, #b548e8 0%, #8027aa 100%);
        }
        dd {
          &.date { flex: none; flex-direction: column; .w(44); .fs(11, 15); .ls(-1px); .bgc(rgba(118, 87, 147, .3)); }
          &.link { flex: 1; .bgc(rgba(118, 87, 147, .2));
            + .date { .ml(2); }
            button { .medium; .wh(80, 32); .fs(14); .c(#fff); .br(8); cursor: default; pointer-events: none; .bgc(#5b5c60); .o(.5); box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
              &.active { .pointer; pointer-events: visible; background: linear-gradient(180deg, #b548e8 0%, #8027aa 100%); .o(1); }
            }
          }
        }
      }
      .rank-data { .c(white); .mb(24);
        dl { .flex;
          + dl { .mt(6); }
        }
        dt, dd { .flex-center; .h(48); }
        dt { flex: none; .w(40); .fs(12);
          .bgc(#424d62);
        }
        dd { flex: 1; .ml(2); .medium; .fs(14); .tc; .bgc(rgba(118, 87, 147, .2)); flex-direction: column;
          span, b { .block; .medium; }
          span { .fs(14, 14); .c(#ff89f3); }
          b { .mt(6); .fs(10, 10); .c(#f6ea46); }
        }
        > dl:first-child { .rel; .mt(48);
          &:before { .cnt; .abs; .l(0); .t(-26); .r(0); .h(20);
            .bgc(#424d62);
          }
          dt, dd { .rel;
            &:before { content: attr(data-label); .abs; .l(-2); .t(-26); .r(0); .fs(12, 20); .tc; .-l(#202328, 2); }
          }
          dt:before { .l(0); border-width: 0; }
        }
      }
      article { .rel; .max-w(320); .mh-c; .font-g;
        > h3 { .fs(20, 38); .c(white); .tc; .mb(23); .w(270); .mh-c; .cover('@{pubg-result}/bg_result_sub_title.svg'); .bg-xc; .ani-base;
          .intro-up(1, { .ani-on; });
          &.final { .w(187);}
        }
        + article { .mt(40);
          &:before { .t(auto); .b(-52); }
        }
        [final-member-table] { .mt(40); }
      }
    }
    .prev-leaderboard { .tc; .mt(40); .p(40, 7); .rel; .max-w(320); .mh-c; .bgc(rgba(0, 0, 0, 0.2));
      &:before { .cnt; .abs; .lt; .wh(100%, 1); .bg('@{pubg-result}/bg_divide_new.svg'); }
      &.open {
        &:after { .cnt; .abs; .lb; .wh(100%, 1); .bg('@{pubg-result}/bg_divide_new.svg'); }
        .main-btn {
          &:before { .contain('@{pubg-result}/up.svg'); .r(55);}
        }
      }
      .main-btn { .wh(100%, 52); .max-w(295); .rel; .pr(17); .mb(16); .bgc(#d13639); .br(8); .font-g;
        span { .ls(-2px); .c(white); .semi-bold; .fs(18, 52);}
        &:before { .cnt; .abs; .rt(40, 50%); .t-y(-50%); .contain('@{pubg-result}/down.svg'); .wh(20);}
      }
      p { .fs(14, 14px); .c(rgba(255, 255, 255, .5)); .font-spoqa; }
      article { .rel; .max-w(320); .mh-c; .font-g;
        + article { .mt(40); }
        [final-member-table] { .mt(50); }
      }
      .rank-data { .c(white); .mb(24);
        dl { .flex;
          + dl { .mt(6); }
        }
        dt, dd { .flex-center; .h(48); }
        dt { flex: none; .w(40); .fs(12);
          //background: linear-gradient(101.01deg, #ff3d00 0%, #7010d0 100%);
          .bgc(#424d62);
        }
        dd { flex: 1; .ml(2); .medium; .fs(14); .tc; .bgc(rgba(118, 87, 147, .2)); flex-direction: column;
          span, b { .block; .medium; }
          span { .fs(14, 14); .c(#ff89f3); }
          b { .mt(6); .fs(10, 10); .c(#f6ea46); }
          s { .fs(12, 16); }
        }
        > dl:first-child { .rel; .mt(48);
          &:before { .cnt; .abs; .l(0); .t(-26); .r(0); .h(20);
            //background: linear-gradient(101.01deg, #ff3d00 0%, #7010d0 100%);
            .bgc(#424d62);
          }
          dt, dd { .rel;
            &:before { content: attr(data-label); .abs; .l(-2); .t(-26); .r(0); .fs(12, 20); .tc; .-l(#202328, 2); }
          }
          dt:before { .l(0); border-width: 0; }
        }
      }
      .show-final { .wh(248, 44); .br(8); .c(white); .fs(16, 44px); background: linear-gradient(101.01deg, #b548e8 0%, #8027aa 100%);}
    }
  }

  @media (@tp-up) {
    .w(710); .mh-c;
  }

  @media (@tl-up) {
    .w(966);
    .inner-holder { .p(60, 0);
      [section-title] {
        .ko { .fs(32, 32); .ml(0); .mb(40);}
      }
      .contents-holder { .p(200, 34);
        &:before { .bg('@{pubg-result}/bg-img.svg'); .wh(100%, 118);}
        &:after {.bg('@{pubg-result}/bg-img.svg'); .wh(100%, 118);}
        .empty { .h(320); .fs(16); .no-repeat('@{pubg-result}/empty_result_tl.svg'); }
        .result {
          h2 { .mb(92); .wh(350, 122); }
          article { .max-w(800);
            //&:before { .wh(860, 1); }
            h3 { .fs(36, 68); .mb(32); .w(470); .cover('@{pubg-result}/tl/bg_result_sub_title.svg'); }
            + article { .mt(52); }
          }
          .final-list { .mb(80); }
          .week-data {
            dl {
              &:nth-child(1) dt span { .bg('@{pubg-intro}/img_week_1.svg'); }
              &:nth-child(2) dt span { .bg('@{pubg-intro}/img_week_2.svg'); }
              &:nth-child(3) dt span { .bg('@{pubg-intro}/img_week_3.svg'); }
              &:nth-child(4) dt span { .bg('@{pubg-intro}/img_week_4.svg'); }
              &:nth-child(5) dt span { .bg('@{pubg-intro}/img_week_5.svg'); }
              + dl { .mt(12); }
            }
            dt, dd { .h(76); }
            dt { flex: none; .w(136); .fs(16, 18); .mr(8);
              span { .ib; .w(85); .pt(40); .no-repeat; .bg-xc; .bg-s(100%, 36); .tc;
                &:before,
                &:after { content: '-'; }
              }
            }
            dd {
              &.date { .w(80); .fs(16, 18); }
              &.link {
                + .date { .ml(8); }
                button { .medium; .wh(180, 48); .fs(20); }
              }
            }
          }
          .rank-data {
            dl + dl { .mt(12); }
            dt, dd { .h(76); }
            dt { .w(136); .fs(26); }
            dd { .ml(8); .fs(26);
              span { .fs(26, 26); }
              b { .mt(8); .fs(14, 16); }
            }
            > dl:first-child { .mt(80);
              &:before { .cnt; .abs; .l(0); .t(-48); .r(0); .h(36); }
              dt, dd { .rel;
                &:before { .l(-8); .t(-48); .fs(20, 36); border-width: 8px; .pl(6);}
              }
              dt:before { border-width: 0; }
            }
          }
        }
        .prev-leaderboard { .p(60, 20); .mt(60); .max-w(840);
          &:before { .w(100%);}
          &.open {
            &:after { .w(100%);}
            .main-btn {
              &:before { .r(265);}
            }
          }
          article { .max-w(800);
            > h3 { .fs(36, 68); .mb(32); .bg('@{pubg-result}/tl/bg_result_sub_title.svg'); }
            + article { .mt(52); }
            .week-data {
              dl {
                &:nth-child(1) dt span { .bg('@{pubg-intro}/img_week_1.svg'); }
                &:nth-child(2) dt span { .bg('@{pubg-intro}/img_week_2.svg'); }
                &:nth-child(3) dt span { .bg('@{pubg-intro}/img_week_3.svg'); }
                &:nth-child(4) dt span { .bg('@{pubg-intro}/img_week_4.svg'); }
                + dl { .mt(12); }
              }
              dt, dd { .h(76); }
              dt { flex: none; .w(136); .fs(16, 18); .mr(8);
                span { .ib; .w(85); .pt(40); .no-repeat; .bg-xc; .bg-s(100%, 36); .tc;
                  &:before,
                  &:after { content: '-'; }
                }
              }
              dd {
                &.date { .w(80); .fs(16, 18); }
                &.link {
                  + .date { .ml(8); }
                  button { .medium; .wh(180, 48); .fs(20); }
                }
              }
            }
            .rank-data { .mb(32);
              dl + dl { .mt(12); }
              dt, dd { .h(76); }
              dt { .w(136); .fs(26); }
              dd { .ml(8); .fs(26);
                span { .fs(26, 26); }
                b { .mt(8); .fs(14, 16); }
              }
              > dl:first-child { .mt(80);
                &:before { .cnt; .abs; .l(0); .t(-48); .r(0); .h(36); }
                dt, dd { .rel;
                  &:before { .l(-8); .t(-48); .fs(20, 36); border-width: 8px; .pl(6);}
                }
                dt:before { border-width: 0; }
              }
            }
            [final-member-table] { .mt(65); }
          }
          .main-btn { .max-w(800); .h(72); .mb(24);
            span { .fs(26, 72px); }
            &:before { .wh(28); .r(245);}
          }
          p { .fs(16);}
          .show-final { .wh(420, 56); .fs(20, 56px);}
        }

      }

    }

  }

  @media (@ds-up) {
    .w(1200);
    .inner-holder { .p(60, 0, 120);
      .contents-holder {
        .empty { .mh-c; .no-repeat('@{pubg-result}/empty_result_ds.svg'); .max-w(920);}
        .prev-leaderboard { .max-w(920); }
      }
    }
  }
}
</style>
